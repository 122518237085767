import clsx from 'clsx';
import { Field, FieldHookConfig, useField } from 'formik';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type CheckboxFieldProps = FieldWrapperPassThroughProps & {
	className?: string;
	labelClassName?: string;
};

export const CheckboxField = (props: CheckboxFieldProps & FieldHookConfig<boolean>) => {
	const { label, className, labelClassName, width } = props;
	const [field, meta] = useField({ ...props, type: 'checkbox' });
	const { error, touched } = meta;

	return (
		<FieldWrapper error={error} touched={touched} width={width}>
			<label className='flex flex-row items-center'>
				<Field
					className={clsx('mr-3 text-success', className)}
					type='checkbox'
					{...field}
				></Field>
				<span className={`${labelClassName}`}>{label}</span>
			</label>
		</FieldWrapper>
	);
};
